import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { MAX_WIDTH, MIN_WIDTH, TRANSITION_DURATION } from '../utils/consts';
import Logo from './Logo';

const Container = styled.header`
  padding: 0.75rem 1rem 0;
  border-bottom: ${(props: { borderColor?: string }) =>
    `1px solid ${props.borderColor}` || 'initial'};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  max-width: ${MAX_WIDTH}px;
  width: 100%;
  margin: auto;

  @media (min-width: ${MAX_WIDTH}px) {
    padding: 0 1rem;
  }

  @media (max-width: ${MIN_WIDTH}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  width: 3rem;
  padding-bottom: 0.75rem;
  position: relative;
  transition: ${TRANSITION_DURATION}ms;

  &:hover {
    opacity: 0.8;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${MIN_WIDTH}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props: { color?: string }) => props.color || 'initial'};
  padding: 0 0.75rem 1rem;

  &:hover {
    box-shadow: ${(props: { color?: string }) =>
      `0 -2.5px 0 ${props.color} inset` || 'initial'};
  }

  &.active {
    color: ${(props: { active?: string }) => props.active || 'initial'};
    box-shadow: ${(props: { active?: string }) =>
      `0 -2.5px 0 ${props.active} inset` || 'initial'};
  }

  &:hover,
  &.active {
    @media (max-width: ${MIN_WIDTH}px) {
      box-shadow: none;
    }
  }

  @media (max-width: ${MIN_WIDTH}px) {
    padding: 0 0 0.5rem;
  }
`;

interface Props {
  primaryColor?: string;
  secondaryColor?: string;
  borderColor?: string;
}

const Header = ({ primaryColor, secondaryColor, borderColor }: Props) => (
  <Container borderColor={borderColor}>
    <Content>
      <LogoContainer>
        <Link to="/">
          <Logo />
        </Link>
      </LogoContainer>
      <Links>
        {['blog', 'portfolio', 'about', 'contact'].map((location) => (
          <StyledLink
            to={`/${location}`}
            key={location}
            color={secondaryColor}
            active={primaryColor}
            activeClassName="active"
          >
            <h3>{location.charAt(0).toUpperCase() + location.slice(1)}</h3>
          </StyledLink>
        ))}
      </Links>
    </Content>
  </Container>
);

export default Header;

/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { MAX_WIDTH, MIN_WIDTH } from '../utils/consts';
import Header from './Header';
import './Layout.scss';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  flex: 1;
  margin: 0 auto;
  padding: 1.5rem 1rem;
`;

const Footer = styled.div`
  width: 100%;
  margin: auto auto 0;
  padding: 2rem 1rem 1rem;
  text-align: center;
  border-top: ${(props: { borderColor?: string }) =>
    `1px solid ${props.borderColor}` || 'initial'};
`;

const FooterContent = styled.div`
  max-width: ${MAX_WIDTH}px;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: ${MIN_WIDTH}px) {
    flex-direction: column;
  }
`;

const Anchor = styled.a`
  padding: 0 0.5rem;
  margin-right: 0.5rem;

  @media (max-width: ${MIN_WIDTH}px) {
    margin-right: initial;
  }

  &:first-child {
    margin-left: auto;

    @media (max-width: ${MIN_WIDTH}px) {
      margin-left: initial;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

interface Props {
  children?: React.ReactNode;
}

const Layout = ({ children }: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            author
            primaryColor
            secondaryColor
            borderColor
          }
        }
      }
    `}
    render={(data) => (
      <Container>
        <Header
          primaryColor={data.site.siteMetadata.primaryColor}
          secondaryColor={data.site.siteMetadata.secondaryColor}
          borderColor={data.site.siteMetadata.borderColor}
        />
        <Main>{children}</Main>
        <Footer borderColor={data.site.siteMetadata.borderColor}>
          <FooterContent>
            © {new Date().getFullYear()} {data.site.siteMetadata.author}
            {[
              { name: 'GitHub', location: 'https://github.com/kheob' },
              {
                name: 'LinkedIn',
                location: 'https://www.linkedin.com/in/kheob/',
              },
              { name: 'Email', location: 'mailto:baichankheo@gmail.com' },
            ].map((link) => (
              <Anchor href={link.location} key={link.name} target="__blank">
                {link.name}
              </Anchor>
            ))}
          </FooterContent>
        </Footer>
      </Container>
    )}
  />
);

export default Layout;
